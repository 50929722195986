import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: fit-content;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff7070;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }

    //  表用のCSS
    table {
      width: 100%;
      margin-bottom: 15px;


      td {
        padding: 5px;
        border: solid 1px rgba(200, 200, 200, 1);
        vertical-align: top;
      }
    }
  }

}

${BreakPoints.large} {
}
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0093 = (props: Props): JSX.Element => {
  const routeParam = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0068/'
  )
  const routeParam2 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0069/'
  )
  const routeParam3 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0073/'
  )
  const routeParam4 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0090/'
  )
  const routeParam5 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0067/'
  )
  const routeParam6 = useRouteParam('https://www.kintaicloud.jp/')
  return (
    <Wrapper>
      <ColumnH1 label="勤怠管理の課題とは？企業が抱えやすい課題とその解決策を紹介" />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2024.03.28</p>
      <section>
        {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
        <img
          src="/images/column/details/c0093.jpg"
          alt="キンクラコラム画像93"
        />
        <p>
          企業の責任者や労務担当者は、従業員の勤怠管理を適切に行う必要があります。しかし、管理すべき情報が多いため、集計作業に負担を負担を感じることもあるかもしれません。その結果、勤怠管理にミスが生じたり、改ざんなどの不正が発生したりすることもあるでしょう。
          <br />
          本記事では、勤怠管理にともなう課題とその解決策について解説します。
        </p>
        <div id="toc1">
          <h1>企業が勤怠管理を行うべき理由とは</h1>
        </div>
        勤怠管理とは、企業が従業員の勤務状況を記録し、把握することを指します。
        では、なぜ勤怠管理を行わなければならないのか？その理由について解説します。
        <a href={routeParam} className="column-detail-link">
          関連記事：勤怠管理システムを導入していない会社で発生するリスクとは？
        </a>
        <div id="toc1-1">
          <h2>従業員を適切に管理するため</h2>
        </div>
        <p>
          企業は従業員の労働時間を適切に管理して過剰労働を防止し、健康を維持する必要があります。適切に勤怠管理を行うことで、時間外労働が過度に増えていないかや休日出勤の頻度などを把握可能です。そして、勤怠状況に応じて人員配置や業務内容を調整することで、より働きやすい職場環境を作ることができます。
        </p>
        <div id="toc1-2">
          <h2>働き方改革に対応するため</h2>
        </div>
        <p>
          社会全体が働き方改革の推進へと舵切りする流れに伴い、労働基準法の改正も定期的に実施されています。例えば2019年には、時間外労働の上限規制や有給休暇の取得義務化などが定められました。
          <br />
          また、従業員の労働時間を把握すること自体も労働基準法で義務化されています。企業には、労働基準法をはじめとした各種法令に対応した勤怠管理が求められているのです。
        </p>
        <div id="toc1-3">
          <h2>コンプライアンスを遵守するため</h2>
        </div>
        <p>
          企業は勤怠管理を行うにあたって、コンプライアンスを意識する必要があります。法令に応じて適切な勤怠管理を行うことは企業の義務です。法令を守るためには、従業員の勤務時間や休暇を正確に管理しなければなりません。コンプライアンスをおろそかにした場合、ブラック企業とみなされ退職者が相次いだり企業全体の評判が落ちたりといった大きな問題に発展するおそれもあります。
          <br />
          <br />
          このように、従業員の適切な管理やコンプライアンス遵守のために、正しい勤怠管理を行うことが大切です。
        </p>
        <div id="toc2">
          <h1>勤怠管理の課題とは</h1>
        </div>
        <img
          src="/images/column/details/c0093_g01.jpg"
          alt="キンクラコラム画像93"
        />
        <p>多くの企業でよくある勤怠管理の課題をご紹介します。</p>
        <div id="toc2-1">
          <h2>集計作業に時間がかかる</h2>
        </div>
        <p>
          勤怠管理の集計作業に手間がかかるため、多くの企業が処理に膨大な時間をかけています。
          <br />
          テレワークやフレックスタイム、時短勤務などの多様化に伴い、勤務時間の集計、給与計算、残業や休日出勤の集計、出張や休暇に関する処理など、集計作業には大きな手間と時間がかかります。
        </p>
        <div id="toc2-2">
          <h2>チェック作業に労力がかかる</h2>
        </div>
        <p>
          勤怠管理のミスを減らすためには、集計後のチェックが必要不可欠です。勤怠の記録に不明点があれば電話やメールで確認し、フィードバックを残すなどの作業をしなければなりません。また、打刻漏れや不正な打刻があったときにも個別の対処が必要です。
          <br />
          たくさんの従業員を抱える企業ほど、集計後のチェック作業は大変なものになるでしょう。作業工数が増えるほどミスも起こりやすくなるため、集計には細心の注意が求められます。
        </p>
        <a href={routeParam2} className="column-detail-link">
          関連記事：手動での勤怠管理からの脱却！無料の勤怠管理システムのメリットとは？
        </a>
        <div id="toc2-3">
          <h2>勤務時間の改ざんが起きる</h2>
        </div>
        <p>
          勤怠管理では、従業員による申告の不正や改ざんに気をつける必要があります。タイムカードを使った勤怠管理を採用している企業では、第三者による不正打刻などの問題が発生しやすいです。また、勤怠管理が自己申告制となっている場合にも、改ざんのリスクがあります。改ざんなどの不正が起きないよう、勤怠管理の方法を徹底する必要があるでしょう。
        </p>
        <a href={routeParam3} className="column-detail-link">
          関連記事：変形労働時間制とは？勤怠管理の効率的な方法を解説
        </a>
        <div id="toc2-4">
          <h2>法改正への対応に労力がかかる</h2>
        </div>
        <p>
          働き方改革やリモートワーク推進をはじめとした社会情勢の変化に伴い、労働基準法などの改正もたびたび行われます。企業は法改正の内容に合わせて、勤怠管理の方法を更新しなければなりません。新制度が施行されるたびに新しい帳票に差し替えたり勤怠管理の運用方法を変更したりするのは大変なものです。何度も勤怠管理の手法を移行したために、社内のルールがあいまいになってしまケースもあります。
          <br />
          <br />
          このように、多くの企業で上記のような課題を抱えています。
        </p>
        {/** ここに9パターンの広告設定する */}
        {props.no === 0 && <Introduction004 />}
        {props.no === 1 && <Introduction005 />}
        {props.no === 2 && <Introduction006 />}
        {props.no === 3 && <Introduction007 />}
        {props.no === 4 && <Introduction008 />}
        {props.no === 5 && <Introduction009 />}
        {props.no === 6 && <Introduction010 />}
        {props.no === 7 && <Introduction011 />}
        {props.no === 8 && <Introduction012 />}
        <div id="toc3">
          <h1>勤怠管理の課題を解決する方法とは</h1>
        </div>
        <img
          src="/images/column/details/c0093_g02.jpg"
          alt="キンクラコラム画像93"
        />
        <p>
          勤怠管理の課題を克服するために、勤怠管理システムの導入を検討しましょう。勤怠管理システムを導入すれば、企業の勤怠管理のすべてをデジタル上で完結させられます。従業員の出退勤だけでなく、残業や休日出勤の記録、シフト作成や管理、休暇の取得など、勤怠に関するあらゆることをシステム上に反映させることが可能です。
          <br />
          勤怠管理システムの導入後には、出退勤や時間外労働の記録を元に給与計算のための集計を自動で行えます。勤怠管理システムを導入して勤怠管理にかかる手間や時間を大きく削減し、業務の効率化につなげるのがおすすめです。
        </p>
        <a href={routeParam4} className="column-detail-link">
          関連記事：無料で使える勤怠管理アプリとは？メリットや注意点も紹介
        </a>
        <div id="toc3-1">
          <h2>勤怠管理システムを導入することによるメリット</h2>
        </div>
        <p>
          勤怠管理システムの導入には、これまでの勤怠管理の課題を解決できる数多くのメリットがあります。特に大きなメリットは、勤怠の集計作業の効率が大幅に上がるという点です。勤怠管理システム上では、パソコンやスマートフォンを使った打刻が可能です。従業員が申請した勤怠状況をシステムが自動的に管理するため、手作業での集計が不要となります。
          <br />
          パソコンやスマートフォンから打刻ができるシステムを導入すれば、テレワークや直行直帰など多彩な働き方にも難なく対応可能です。デジタル上で勤怠管理することで、ミスや不正が減少し、情報をより正確に管理できます。さらに、勤怠管理システムは法改正への対応を自動で行うので、勤怠管理の担当者が都度情報をキャッチアップする必要もなくなります。
        </p>
        <a href={routeParam5} className="column-detail-link">
          関連記事：勤怠管理は経費精算付きのクラウドがおすすめ！ その理由とは...
        </a>
        <p>
          以上のように、勤怠管理システムを導入することによって勤怠管理の課題を解決できます。
        </p>
        <div id="toc4">
          <h1>勤怠管理システムを導入するなら「キンクラ」</h1>
        </div>
        <p>
          この記事で紹介したように、多くの企業で勤怠管理に課題を抱えています。もし、勤怠管理に課題を抱えているならば、先述したように勤怠管理システムを導入することをおすすめします。とはいえ、勤怠管理システムは何を導入すればいいのか分からないという人も多いでしょう。
          <br />
          もし、勤怠管理システムを導入するなら「キンクラ」がおすすめです。キンクラは、全機能が無料でお試しできるトライアルがあるため、まずは導入してみて使用感を確かめることが可能です。
        </p>
        <a href={routeParam6} className="column-detail-link">
          ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
        </a>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0093
